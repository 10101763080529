<template>
  <v-app id="inspire">
    <v-main>
      <v-row>
        <v-col class="hidden-sm-and-down" sm="12" md="7">
          <v-img
            :src="require('@/app/assets/images/login/img-portada.png')"
            class="image"
            height="100vh"
          />
        </v-col>
        <v-col cols="12" sm="12" md="5">
          <v-card-text class="mt-12">
            <template>
              <v-row>
                <v-col align="center">
                  <v-img
                    :src="require('@/app/assets/images/login/logo-firecity.png')"
                    height="170"
                    width="170"
                  />
                </v-col>
              </v-row>
            </template>

            <h1 class="text-center orange--text text--accent-3 mb-5">{{ title }}</h1>
            <slot></slot>
          </v-card-text>
        </v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    step: 1,
  }),
  name: 'AuthLayout',
};
</script>

<style scoped>
.root {
  height: 100vh;
}
.image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
</style>
