<template>
  <auth title="INICIAR SESIÓN">
    <sign-in-form></sign-in-form>
  </auth>
</template>

<script>
import SignInForm from './containers/SignInForm';
import Auth from '@/app/layouts/auth/AuthLayout';
export default {
  name: 'SignIn',
  components: { Auth, SignInForm },
};
</script>

<style scoped></style>
