<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <!--              <v-text-field outlined label="Name" name="Name" prepend-icon="person" type="text" />-->
    <v-text-field
      :disabled="loadingLogin"
      :rules="rules.email"
      outlined
      v-model="form.email"
      label="Email"
      name="Email"
      prepend-icon="email"
      type="text"
      required
    />
    <v-text-field
      :disabled="loadingLogin"
      required
      prepend-icon="lock"
      outlined
      label="Contraseña"
      name="Password"
      v-model="form.password"
      :rules="[rules.required]"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      :type="showPassword ? 'text' : 'password'"
      @click:append="showPassword = !showPassword"
    ></v-text-field>
    <!--    <div class="d-flex justify-end">
      <a
        @click="forgotPassword"
        class="orange&#45;&#45;text app-link text-decoration-none font-weight-medium"
      >
        ¿Olvidaste tu contraseña?
      </a>
    </div>-->
    <div class="text-center mt-5">
      <v-btn :loading="loadingLogin" @click="login" :disabled="!valid" color="primary">
        <v-icon left> mdi-send </v-icon>
        INGRESAR
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

const valueForm = {
  email: '',
  password: '',
};

export default {
  name: 'SignInForm',

  data: () => ({
    valid: true,
    rules: {
      required: (value) => !!value || 'La contraseña es requerida',
      email: [
        (v) => !!v || 'Email es requerido',
        (v) => /.+@.+\..+/.test(v) || 'Formato de email invalido',
      ],
    },
    form: { ...valueForm },
    showPassword: false,
    loading: false,
  }),
  computed: {
    ...mapGetters('auth', ['loadingLogin']),
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    async login() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      const data = { email: this.form.email, password: this.form.password };
      await this.signIn(data);
    },
    forgotPassword() {
      this.$router.push({ path: '/forgot-password' });
    },
  },
};
</script>
